"use client";

import { datadogRum } from "@datadog/browser-rum";
import { env } from "next-runtime-env";

// These all map to required parameters which can't be undefined
// so to make type checking happy, we cast them as strings.
const applicationId = env("NEXT_PUBLIC_DD_APPLICATION_ID") as string;
const clientToken = env("NEXT_PUBLIC_DD_CLIENT_TOKEN") as string;
const backendUrl = env("NEXT_PUBLIC_BACKEND_URL") as string;
const sessionSampleRate = parseInt(env("NEXT_PUBLIC_SESSION_SAMPLE_RATE") || "0", 10) as number;

// Sample rates can be adjusted as needed
// The sessionReplaySampleRate parameter is a percentage of sessionSampleRate.
datadogRum.init({
  applicationId,
  clientToken,
  // Percentage of sessions to sample. This is not the same as replays and does not record videos.
  sessionSampleRate,
  // `site` refers to the Datadog site parameter of your organization
  // see https://docs.datadoghq.com/getting_started/site/
  site: "datadoghq.com",
  service: env("NEXT_PUBLIC_DD_SERVICE"),
  env: env("NEXT_PUBLIC_ENV"),
  version: env("NEXT_PUBLIC_DD_VERSION"),
  // Session replays capture videos of the session at the given privacy level
  sessionReplaySampleRate: parseInt(env("NEXT_PUBLIC_SESSION_REPLAY_SAMPLE_RATE") || "0", 10),
  // Setting defaultPrivacyLevel to mask mode masks all HTML text, user input, images, links and data-* attributes.
  // Text on your application is replaced with X, rendering the page into a wireframe.
  // https://docs.datadoghq.com/real_user_monitoring/session_replay/browser/privacy_options/
  defaultPrivacyLevel: "mask",
  enablePrivacyForActionName: true,
  // What to track for RUM
  trackResources: true,
  trackLongTasks: true,
  trackUserInteractions: true,
  // Percentage traces to sample to connect to APM
  // https://docs.datadoghq.com/real_user_monitoring/platform/connect_rum_and_traces/
  traceSampleRate: parseInt(env("NEXT_PUBLIC_TRACE_SAMPLE_RATE") || "0", 10),
  // Specify URLs to propagate trace headers for connection between RUM and backend APM trace
  allowedTracingUrls: [{ match: backendUrl, propagatorTypes: ["tracecontext"] }],
});

export default function DatadogInit() {
  // Render nothing - this component is only included so that the init code
  // above will run client-side
  return null;
}
